<template>
  <ul class="brand-style-2">
    <li v-for="(brand, i) in brandImages" :key="i">
      <a :href="brand.url">
        <img :src="brand.src" alt="Logo Images" />
      </a>
    </li>
  </ul>
</template>

<script>
export default {
  props: ["brandImagesComponent"],
  data() {
    return {
      brandImages: [],
    };
  },
  mounted() {
    this.getClients();
  },
  methods: {
    getClients() {
      this.brandImagesComponent.forEach((data) => {
        this.brandImages.push({
          src: data.attributes.logo.data.attributes.formats.thumbnail.url, 
          url: data.attributes.url, /* poner url de los clientes */ 
        });
      });
    },
  },
};
</script>
