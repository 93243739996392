<template>
  <v-row>
    <!-- Start Single Team Area  -->
    <v-col
      lg="4"
      md="4"
      sm="6"
      cols="6"
      v-for="team in teamContent"
      :key="team.id"
    >
      <div class="team">
        <div class="thumbnail">
          <img :src="team.attributes.profilePicture.data.attributes.formats.medium.url" alt="Team Images" />
        </div>
        <div class="content">
          <h4 class="title">{{ team.attributes.name }}</h4>
          <p class="designation">{{ team.attributes.jobTitle }}</p>
        </div>
        <SocialIcon :id="team.id" />
      </div>
    </v-col>
    <!-- End Single Team Area  -->
  </v-row>
</template>

<script>
import SocialIcon from "../../components/social/Social";
export default {
  props: ["teamContent"],
  components: {
    SocialIcon,
  }
};
</script>
