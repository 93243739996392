<template>
  <div>
    <VueSlickCarousel
      v-bind="settings"
      class="slider-activation rn-slick-dot rn-slick-activation dot-light mb--0"
    >
      <div
        class="slide slide-style-2 fullscreen bg_image"
        data-black-overlay="8"
        v-for="(slider, i) in sliderContent"
        :key="i"
        :style="{
          backgroundImage: 'url(' + slider.heroCover.data.attributes.url + ')',
        }"
      >
        <v-container>
          <v-row>
            <v-col cols="12">
              <div class="inner text-left">
                <h1 class="heading-title">{{ slider.heroTitle }}</h1>
                <p class="description">
                  {{ slider.heroBody }}
                </p>
                <div class="slide-btn">
                  <router-link
                    class="rn-button-style--2 btn_solid"
                    to="/contacto#general-form"
                    >Conversemos</router-link
                  >
                </div>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </div>
      <!-- End Single Slide  -->
    </VueSlickCarousel>
  </div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";

export default {
  components: { VueSlickCarousel },
  props: ["sliderContent"],
  data() {
    return {
      settings: {
        fade: true,
        dots: true,
        arrows: true,
        infinite: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        margin: 20,
      },
    };
  },
};
</script>

<style lang="scss">
.slick-slide {
  img {
    display: block;
    width: 100%;
  }
}
</style>
