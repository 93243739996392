<template>
  <ul class="social-icon" v-if="socialList">
    <li v-for="(social, i) in socialList" :key="i">
      <a :href="social.url" target="_blank"
        ><i class="fab" :class="social.icon"></i
      ></a>
    </li>
  </ul>
</template>
<script>
import axios from "axios";
export default {
  name: "Social",
  props: ["id"],
  data() {
    return {
      socialList: null,
    };
  },
  mounted() {
    this.getOnePerson();
  },
  methods: {
    async getOnePerson() {
      try {
        const r = await axios(`${this.$apiUrl}/api/team-members/${this.id}`);
        this.socialList = [];
        if (r.data.data.attributes.socialUrl) {
          this.socialList.push({
            icon: "fa-linkedin-in",
            url: r.data.data.attributes.socialUrl,
          });
        }
      } catch (err) {
        console.log(err);
      }
    },
  },
};
</script>
