<template>
  <div>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <div v-if="home">
      <!-- Start Header Area -->
      <Header>
        <img
          slot="logo"
          src="../../assets/images/logo/Logo-Inflow-Hor-BB@2x.png"
          style="max-width: 80%; max-height: 80%"
        />
      </Header>

      <!-- End Header Area -->

      <!-- Start Slider Area -->
      <div class="rn-slider-area poss_relative" id="home">
        <div>
          <SliderTwo :sliderContent="home.attributes.titleSliders" />
        </div>
      </div>

      <!-- End Slider Area -->

      <!-- Start Brand Area -->
      <div
        class="rn-brand-area bg_color--5 rn-section-gap"
        v-if="customers.length > 0"
      >
        <v-container>
          <v-row>
            <v-col cols="12">
              <div class="section-title section-title--2 text-center">
                <h4>Han confiado en nosotros</h4>
              </div>
              <Brand :brandImagesComponent="customers" />
            </v-col>
          </v-row>
        </v-container>
      </div>
      <!-- End Brand Area -->

      <!-- Start Service Area  -->
      <div
        class="rn-service-area service-area rn-section-gap bg_color--1"
        v-if="product_lines.length > 0"
      >
        <v-container>
          <v-row>
            <v-col cols="12" class="mb--20 mb_sm--0">
              <div class="section-title section-title--2 text-center">
                <h2>¿Cómo te ayudamos?</h2>
                <p>
                  {{ home.attributes.servicesIntro }}
                </p>
              </div>
            </v-col>
          </v-row>
          <ServiceThree :serviceContentHome="product_lines" />
        </v-container>
      </div>
      <!-- Start Service Area  -->

      <!-- Start Portfolio Area  -->
      <!-- <div
        class="rn-portfolio-area rn-section-gap bg_color--5"
        v-if="projects.length > 0"
      >
        <v-container>
          <v-row>
            <v-col lg="12">
              <div class="section-title section-title--2 text-center mb--20">
                <h2 class="heading-title">Algunos de nuestros proyectos</h2>
                <p>
                  {{ home.attributes.projectIntro }}
                </p>
              </div>
            </v-col>
          </v-row>
          <PortfolioFour :portfolioContentHome="projects" />
        </v-container>
      </div> -->
      <!-- Start Portfolio Area  -->

      <!-- Start team Area  -->
      <div
        class="rn-team-area rn-section-gap bg_color--1"
        v-if="team_members.length > 2"
      >
        <v-container>
          <v-row>
            <v-col cols="12">
              <div class="section-title section-title--2 text-center mb--20">
                <h2 class="heading-title">Equipo de trabajo</h2>
                <p>
                  {{ home.attributes.teamIntro }}
                </p>
              </div>
            </v-col>
          </v-row>
          <Team :teamContent="team_members" />
        </v-container>
      </div>
      <!-- End team Area  -->

      <!-- Start Counter Area -->

      <div class="rn-countreupup-area rn-section-gap bg_color--5">
        <v-container>
          <CounterOne />
        </v-container>
      </div>
      <!-- End Counter Area -->

      <!-- Start blog Area  -->
      <div class="rn-blog-area rn-section-gap bg_color--5" v-if="home">
        <v-container>
          <v-row>
            <v-col cols="12">
              <div class="section-title section-title--2 text-center mb--20">
                <h2 class="heading-title">Artículos</h2>
                <p>
                  {{ home.attributes.blogIntro }}
                </p>
              </div>
            </v-col>
          </v-row>

          <BlogThree />
        </v-container>
      </div>
      <!-- Start blog Area  -->

      <!-- Start Call to action Area  -->
      <!-- End Call to action Area  -->

      <!-- Start Footer Area  -->
      <Footer />
    </div>
    <!-- End Footer Area  -->
  </div>
</template>
<script>
import axios from "axios";
import Header from "../../components/header/Header";
import SliderTwo from "../../components/slider/SliderTwo";
import Brand from "../../components/brand/Brand";
import ServiceThree from "../../components/service/ServiceThree";
import AboutTwo from "../../components/about/AboutTwo";
import AboutThree from "../../components/about/AboutThree";
import PortfolioFour from "../../components/portfolio/PortfolioFour";
import Team from "../../components/team/Team";
import BlogThree from "../../components/blog/BlogThree";
import Footer from "../../components/footer/Footer";
import CounterOne from "../../components/counter/CounterOne";

export default {
  components: {
    Header,
    SliderTwo,
    Brand,
    ServiceThree,
    AboutTwo,
    AboutThree,
    PortfolioFour,
    Team,
    BlogThree,
    Footer,
    CounterOne,
  },
  data() {
    return {
      logo: null,
      overlay: false,
      home: null,
      product_lines: [],
      customers: [],
      projects: [],
      team_members: [],
    };
  },
  created() {
    this.getHome();
    this.getProductLines();
    this.getCustomers();
    this.getProjects();
    this.getTeamMembers();
  },
  methods: {
    async getHome() {
      try {
        const r = await axios(
          `${this.$apiUrl}/api/home?&populate[titleSliders][populate]=heroCover`
        );
        this.home = r.data.data;
      } catch (err) {
        console.log(err);
      }
    },
    async getProductLines() {
      try {
        const r = await axios(`${this.$apiUrl}/api/product-lines?populate=*`);
        this.product_lines = r.data.data;
      } catch (err) {
        console.log(err);
      }
    },
    async getCustomers() {
      try {
        const r = await axios(`${this.$apiUrl}/api/customers?populate=logo`);
        this.customers = r.data.data;
      } catch (err) {
        console.log(err);
      }
    },
    async getProjects() {
      try {
        const r = await axios(
          `${this.$apiUrl}/api/projects?populate=cover,customer`
        );
        this.projects = r.data.data;
      } catch (err) {
        console.log(err);
      }
    },
    async getTeamMembers() {
      try {
        const r = await axios(
          `${this.$apiUrl}/api/team-members?populate=profilePicture`
        );
        this.team_members = r.data.data;
      } catch (err) {
        console.log(err);
      }
    },
  },
};
</script>
