<template>
  <v-row>
    <!-- Start Single Portfolio  -->
    <v-col
      lg="4"
      md="4"
      sm="6"
      cols="12"
      class="mt--40"
      v-for="(item, i) in portfolioContent"
      :key="i"
    >
      <div class="portfolio">
        <div class="thumbnail-inner">
          <v-img class="thumbnail" :src="item.src"></v-img>
          <v-img class="bg-blr-image" :src="item.src"></v-img>
        </div>
        <div class="content">
          <div class="inner" v-if="portfolioContentHome">
            <!-- <p>{{ item.meta }}</p> -->
            <h4 style="color: white">
              {{ item.title }}
            </h4>
            <p class="hidden-sm-and-down">{{ item.resumen }}</p>
          </div>
          <div class="inner" v-if="portfolioContentComponent">
            <!-- <p>{{ meta.attributes.name }}</p> -->
            <h4>
              <router-link to="portfolio-details">{{ item.title }}</router-link>
            </h4>
            <p class="hidden-sm-and-down">{{ item.resumen }}</p>
            <div class="portfolio-button">
              <router-link class="rn-btn" :to="urlComponent + item.slug"
                >Detalles</router-link
              >
            </div>
          </div>
        </div>
      </div>
    </v-col>
    <!-- End Single Portfolio  -->
  </v-row>
</template>

<script>
import axios from "axios";
export default {
  props: ["portfolioContentHome", "portfolioContentComponent", "urlComponent"],
  data() {
    return {
      portfolioContent: [],
      clientes: {},
    };
  },
  mounted() {
    if (this.portfolioContentHome) this.getClientes();
    if (this.portfolioContentComponent) this.getData();
  },
  methods: {
    getData() {
      this.portfolioContentComponent.forEach((element) => {
        this.portfolioContent.push({
          id: element.id,
          src: element.attributes.media.data[0].attributes.formats.medium.url,
          // meta: element.attributes.categorias,
          title: element.attributes.name,
          resume: element.attributes.description,
          slug: element.attributes.slug,
        });
      });
    },
    async getClientes() {
      try {
        const r = await axios(`${this.$apiUrl}/api/customers`);
        r.data.data.forEach((customer) => {
          this.clientes[customer.id] = customer.attributes.name;
        });

        this.getPortfolioContent();
      } catch (err) {
        console.log(err);
      }
    },
    async getPortfolioContent() {
      await this.portfolioContentHome.forEach((element) => {
        this.portfolioContent.push({
          id: element.id,
          src: element.attributes.cover.data.attributes.formats.medium.url,
          resumen: element.attributes.description,
          meta: this.clientes[element.attributes.customer.data.id],
          title: element.attributes.title,
          slug: element.attributes.slug,
        });
      });
      console.log(this.portfolioContentHome)
    },
  },
};
</script>
